* {
  word-break: keep-all;
}

h1,h2,h3,h4,h5,h6,p {
  line-height: 1.6em;
  
  font-family: -apple-system,
    'BlinkMacSystemFont',
    'Roboto',
    'Helvetica Neue',
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}